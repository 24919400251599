const AppConfig = {
    apiUrl: 'https://www.originsbackend.online/api',
    ImageUrl: 'https://www.originsbackend.online/Images',

    //apiUrl: 'https://qrmenu.runasp.net/api',
    //ImageUrl: 'https://qrmenu.runasp.net/Images',

    //apiUrl: 'https://internetservicespos.online:1997/QRMenu_WebAPI/api',
    //ImageUrl: 'https://internetservicespos.online:1997/QRMenu_WebAPI/Images',

    //apiUrl: 'https://localhost:7064/api',
    //ImageUrl: 'https://localhost:7064/Images',

    KeyEncryptoJS:'%2^Y*j^7(v7$G?8=',
    IVEncryptoJS:'j%262$@4h&j#y(0q'
  };
  
  export default AppConfig;